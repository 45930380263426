import { UserProfile } from "../models/UserProfile";
import { convertDataToVcard } from "./VCardService";

export type CardPlaceholder =
  | "{VCard}"
  | "{PhotoUrl}"
  | "{DisplayName}"
  | "{GivenName}"
  | "{Surname}"
  | "{MobilePhone}"
  | "{BusinessPhone}"
  | "{Mail}"
  | "{JobTitle}"
  | "{Street}"
  | "{PostalCode}"
  | "{City}"
  | "{Country}";

export type PlaceholderData = Map<CardPlaceholder, string | null | undefined>;

export const ExamplePlaceholderData = (() => {
  const userProfile: UserProfile = {
    displayName: "John Smith",
    givenName: "John",
    surname: "Smith",
    companyName: "Smith Inc.",
    businessPhones: "01234-56789",
    mobilePhone: "98765-43210",
    mail: "john@smith.de",
    jobTitle: "System Administrator",
    streetAddress: "Examplestreet 2",
    postalCode: "12345",
    city: "Examplecity",
    country: "Exampleland",
  };

  return new Map<CardPlaceholder, string | null | undefined>([
    ["{VCard}", convertDataToVcard(userProfile)],
    ["{PhotoUrl}", "generic_pfp.png"],
    ["{DisplayName}", userProfile.displayName],
    ["{GivenName}", userProfile.givenName],
    ["{Surname}", userProfile.surname],
    ["{MobilePhone}", userProfile.mobilePhone],
    ["{BusinessPhone}", userProfile.businessPhones],
    ["{Mail}", userProfile.mail],
    ["{JobTitle}", userProfile.jobTitle],
    ["{Street}", userProfile.streetAddress],
    ["{PostalCode}", userProfile.postalCode],
    ["{City}", userProfile.city],
    ["{Country}", userProfile.country],
  ]);
})();

export const fillPlaceholders = (
  text: string,
  placeholderData: PlaceholderData
) => {
  return text.replaceAll(/{(\w+)}/g, (placeholderWithDelimiters) => {
    return (
      placeholderData.get(placeholderWithDelimiters as CardPlaceholder) ?? ""
    );
  });
};
