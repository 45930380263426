import Dexie from "dexie";
import { CardStructure } from "../models/CardStructure";

export class BusinessCardCreatorDatabase extends Dexie {
  cards!: Dexie.Table<CardStructure, number>;

  constructor() {
    super("bcc");

    this.version(1).stores({
      cards: "++id",
    });
  }
}

export const bccDb = new BusinessCardCreatorDatabase();
