import { Button, Dialog } from "@fluentui/react-northstar";
import html2canvas from "html2canvas";
import { useContext, useEffect, useRef, useState } from "react";

import {
  AreaWidgetData,
  AvatarAreaWidgetData,
  QrCodeAreaWidgetData,
  TextAreaWidgetData,
  isAvatarAreaWidgetData,
  isQrCodeAreaWidgetData,
  isTextAreaWidgetData,
} from "../../models/AreaWidgetData";
import { CardStructure } from "../../models/CardStructure";
import { UserProfile } from "../../models/UserProfile";
import {
  addCardStructure,
  addCardStructures,
  getCardStructures,
  setCardStructure,
  setCards,
} from "../../services/CardConfigService";
import { convertDataToVcard } from "../../services/VCardService";
import { DesignNumberContext } from "../App";
import { Card } from "../card-component/Card";
import { DesignCreator } from "../design-creator-component/DesignCreator";

export const Design = (props: {
  profileData: UserProfile;
  profilePictureDimensions: number[];
  photoUrl?: string;
}) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const downloadRef = useRef<HTMLAnchorElement | null>(null);
  const uploadRef = useRef<HTMLInputElement | null>(null);

  const { designNumber, setDesignNumber } = useContext(DesignNumberContext);

  const saveDesignNumber = (designNumber: number) => {
    setDesignNumber(designNumber);
  };

  const downloadCard = () => {
    if (!cardRef.current) {
      return;
    }

    html2canvas(cardRef.current, {
      scale: 4,
      width: cardRef.current.clientWidth,
      height: cardRef.current.clientHeight,
    })
      .then((canvas) => {
        if (!downloadRef.current) {
          return;
        }

        downloadRef.current.download = `my_business_card_${designNumber}.png`;
        downloadRef.current.href = canvas.toDataURL("image/png");
        downloadRef.current.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [card, setCard] = useState<CardStructure | null>(null);
  const [cardCount, setCardCount] = useState<number>(0);

  useEffect(() => {
    const getCards = async () => {
      const cards = await getCardStructures();

      setCardCount(cards.length);

      if (designNumber >= cards.length || designNumber < 0) {
        setDesignNumber(0);
      }

      setCard(cards[designNumber]);
    };

    getCards();
  }, [designNumber, setDesignNumber]);

  const [createDesignDialogOpen, setCreateDesignDialogOpen] = useState(false);
  const [areaWidgets, setAreaWidgets] = useState<AreaWidgetData[]>([]);
  const [baseImage, setBaseImage] = useState<string | null>(null);
  const [saveOverwriteDesign, setSaveOverwriteDesign] = useState<number | null>(
    null
  );

  const closeDialog = () => {
    setCreateDesignDialogOpen(false);
    setAreaWidgets([]);
    setBaseImage(null);
    setSaveOverwriteDesign(null);
  };

  const saveDesign = async () => {
    const newCard: CardStructure = {
      baseImage: baseImage!,
      texts: [],
    };

    areaWidgets.forEach((widget) => {
      if (isQrCodeAreaWidgetData(widget)) {
        newCard.qrCode = {
          position: [widget.x, widget.y],
          size: widget.width,
          value: "{VCard}",
        };
      }

      if (isAvatarAreaWidgetData(widget)) {
        const settings = widget.settings;

        newCard.profilePicture = {
          circular: settings.circular,
          image: "{PhotoUrl}",
          position: [widget.x, widget.y],
          size: widget.width,
        };
      }

      if (isTextAreaWidgetData(widget)) {
        const settings = widget.settings;

        newCard.texts!.push({
          text: settings.text,
          position: [widget.x, widget.y],
          size: [widget.width, widget.height],
          textSize: settings.size,
          centered: settings.centered,
          color: settings.color,
          fontWeight: settings.weight,
        });
      }
    });

    if (saveOverwriteDesign === null) {
      await addCardStructure(newCard);
      getCardStructures().then((cards) => setDesignNumber(cards.length - 1));
    } else {
      await setCardStructure(saveOverwriteDesign, newCard);
      setCard(newCard);
    }

    closeDialog();
  };

  const editDesign = () => {
    if (!card) {
      return;
    }

    const widgets: AreaWidgetData[] = [];

    if (card.qrCode) {
      const widget = new QrCodeAreaWidgetData();
      widget.x = card.qrCode.position[0];
      widget.y = card.qrCode.position[1];
      widget.width = card.qrCode.size;
      widget.height = 0;

      widgets.push(widget);
    }

    if (card.profilePicture) {
      const widget = new AvatarAreaWidgetData({
        circular: card.profilePicture.circular,
      });
      widget.x = card.profilePicture.position[0];
      widget.y = card.profilePicture.position[1];
      widget.width = card.profilePicture.size;
      widget.height = 0;

      widgets.push(widget);
    }

    card.texts?.forEach((textElement) => {
      const widget = new TextAreaWidgetData({
        centered: textElement.centered,
        color: textElement.color,
        size: textElement.textSize,
        text: textElement.text,
        weight: textElement.fontWeight,
      });
      widget.x = textElement.position[0];
      widget.y = textElement.position[1];
      widget.width = textElement.size[0];
      widget.height = textElement.size[1];

      widgets.push(widget);
    });

    setAreaWidgets(widgets);

    setBaseImage(card.baseImage);

    setSaveOverwriteDesign(designNumber);

    setCreateDesignDialogOpen(true);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const deleteDesign = async () => {
    const cards = await getCardStructures();

    cards.splice(designNumber, 1);

    await setCards(cards);
    setCardCount(cards.length);

    if (designNumber >= cards.length) {
      setDesignNumber(cards.length - 1);
    } else {
      setCard(cards[designNumber]);
    }

    setDeleteDialogOpen(false);
  };

  const uploadDesign = () => {
    uploadRef.current?.click();
  };

  const onDesignUploaded = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const cards: CardStructure[] = [];

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      if (!file.type.startsWith("application/json")) {
        continue;
      }

      try {
        const content = await file.text();
        cards.push(JSON.parse(content) as CardStructure);
      } catch (error) {
        console.error(error);
      }
    }

    if (cards.length === 0) {
      return;
    }

    await addCardStructures(cards);
    getCardStructures().then((cards) => setDesignNumber(cards.length - 1));
  };

  const downloadDesign = () => {
    if (!downloadRef.current || !card) {
      return;
    }

    downloadRef.current.download = `my_business_card${designNumber}.json`;
    downloadRef.current.href = `data:text/plain,${encodeURIComponent(
      JSON.stringify(card)
    )}`;
    downloadRef.current.click();
  };

  return (
    <div className="flex h-screen flex-col items-center rounded-xl px-8 py-4 shadow-blur-lg shadow-[#b3b3b3]">
      <div className="prose w-full dark:prose-invert">
        <h2 className="mb-2 text-center">Design</h2>
        <p className="mb-2 text-center">
          Select and download your business card design
        </p>
      </div>

      <div className="flex min-h-0 flex-row justify-center">
        <div className="float-right flex w-min flex-col items-end justify-start gap-2">
          {Array.from(Array(cardCount).keys()).map((n) => (
            <input
              type="radio"
              name="designSelection"
              key={n}
              className={`
                h-[27px] 
                w-[27px] cursor-pointer 
                appearance-none rounded-2xl border-4 border-solid 
                border-[#dbdbdb] 
                hover:bg-[#4742d4] ${
                  designNumber === n ? "bg-[#403cad]" : "bg-[#dbdbdb]"
                }`}
              onClick={() => saveDesignNumber(n)}
            ></input>
          ))}

          <Dialog
            className="h-[95%] !w-[95%] grid-rows-[0fr_auto_0fr] [&>div[data-aa-class=Dialog\_\_content]]:flex [&>div[data-aa-class=Dialog\_\_content]]:min-h-0"
            backdrop={true}
            closeOnOutsideClick={false}
            open={createDesignDialogOpen}
            trigger={
              <button
                type="button"
                className="
                  hidden h-[27px] w-[27px] appearance-none flex-row
                  items-center
                  justify-center rounded-2xl
                  border-4 border-solid border-[#dbdbdb] bg-[#dbdbdb] lg:flex"
                onClick={() => setCreateDesignDialogOpen(true)}
              >
                <div className="group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className="stroke-[#403cad] group-hover:stroke-[#4742d4]"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 12h6m0 0h6m-6 0v6m0-6V6"
                    />
                  </svg>
                </div>
              </button>
            }
            cancelButton="Cancel"
            onCancel={closeDialog}
            confirmButton={baseImage ? "Save" : null}
            onConfirm={saveDesign}
            header={
              saveOverwriteDesign === null
                ? "Create new design"
                : "Edit design"
            }
            content={
              <DesignCreator
                baseImage={baseImage}
                setBaseImage={setBaseImage}
                areaWidgets={areaWidgets}
                setAreaWidgets={setAreaWidgets}
              />
            }
          />

          <button
            type="button"
            className="flex h-[27px] w-[27px] appearance-none
                  flex-row
                  items-center justify-center
                  rounded-2xl border-4 border-solid border-[#dbdbdb] bg-[#dbdbdb]"
            onClick={uploadDesign}
          >
            <div className="group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="stroke-[#403cad] group-hover:stroke-[#4742d4]"
              >
                <path
                  d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <polyline
                  fill="none"
                  points="7.9 6.7 12 2.7 16.1 6.7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <line
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  x1="12"
                  x2="12"
                  y1="16.3"
                  y2="4.8"
                />
              </svg>
            </div>
          </button>
        </div>

        {card && (
          <div className="flex flex-col items-center gap-2">
            <Card
              ref={cardRef}
              placeholderData={
                new Map([
                  ["{VCard}", convertDataToVcard(props.profileData)],
                  ["{PhotoUrl}", props.photoUrl],
                  ["{DisplayName}", props.profileData.displayName],
                  ["{GivenName}", props.profileData.givenName],
                  ["{Surname}", props.profileData.surname],
                  ["{MobilePhone}", props.profileData.mobilePhone],
                  ["{BusinessPhone}", props.profileData.businessPhones],
                  ["{Mail}", props.profileData.mail],
                  ["{JobTitle}", props.profileData.jobTitle],
                  ["{Street}", props.profileData.streetAddress],
                  ["{PostalCode}", props.profileData.postalCode],
                  ["{City}", props.profileData.city],
                  ["{Country}", props.profileData.country],
                ])
              }
              structure={card}
            />

            <Button
              onClick={downloadCard}
              primary
              content="Download business card"
              className="w-full"
            />

            <Button
              onClick={editDesign}
              primary
              content="Edit design"
              className="!hidden w-full lg:!inline-flex"
            />

            <Button
              onClick={downloadDesign}
              primary
              content="Download design"
              className="w-full"
            />

            <Dialog
              backdrop={true}
              closeOnOutsideClick={true}
              open={deleteDialogOpen}
              trigger={
                <Button
                  onClick={() => setDeleteDialogOpen(true)}
                  primary
                  content="Delete design"
                  className="w-full"
                />
              }
              cancelButton="Cancel"
              onCancel={() => setDeleteDialogOpen(false)}
              confirmButton="Delete"
              onConfirm={deleteDesign}
              header="Delete design"
              content="Do you really want to permanently delete this design?"
            />

            <a ref={downloadRef} className="hidden"></a>
            <input
              className="hidden"
              type="file"
              accept=".json"
              multiple={true}
              onChange={onDesignUploaded}
              ref={uploadRef}
            ></input>
          </div>
        )}
      </div>
    </div>
  );
};
