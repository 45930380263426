export const Checkbox = (props: {
  id: string;
  label: string;
  getValue: () => boolean;
  setValue: (value: boolean) => void;
  className?: string;
}) => {
  return (
    <div
      className={`flex w-full flex-row justify-center gap-2 ${props.className}`}
    >
      <label htmlFor={props.id} className="w-1/2 text-center">
        {props.label}
      </label>

      <input
        className="w-1/2 scale-75"
        id={props.id}
        type="checkbox"
        ref={(ckb) => {
          if (!ckb) {
            return;
          }

          ckb.checked = props.getValue();
        }}
        onChange={(event) => {
          props.setValue(event.target.checked);
        }}
      />
    </div>
  );
};
