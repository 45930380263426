import { Button, Image } from "@fluentui/react-northstar";
import { useEffect, useRef, useState } from "react";
import {
  AreaWidgetData,
  AvatarAreaWidgetData,
  QrCodeAreaWidgetData,
  TextAreaWidgetData,
  isAvatarAreaWidgetData,
  isTextAreaWidgetData,
} from "../../models/AreaWidgetData";
import {
  ExamplePlaceholderData,
  fillPlaceholders,
} from "../../services/CardTextPlaceholderService";
import { AreaWidget } from "../area-widget-component/AreaWidget";
import { Checkbox } from "../checkbox-component/Checkbox";

export const DesignCreator = (props: {
  baseImage: string | null;
  setBaseImage: (image: string) => void;
  areaWidgets: AreaWidgetData[];
  setAreaWidgets: (widgets: AreaWidgetData[]) => void;
}) => {
  const propsRef = useRef(props);
  propsRef.current = props;

  const baseImageInputRef = useRef<HTMLInputElement | null>(null);

  const designContainerRef = useRef<HTMLDivElement | null>(null);

  const [displayDesign, setDisplayDesign] = useState(false);

  const [widgetToSnapX, setWidgetToSnapX] = useState<AreaWidgetData | null>(
    null
  );

  const [selectedWidget, setSelectedWidget] = useState<AreaWidgetData | null>(
    null
  );

  const selectedWidgetRef = useRef<AreaWidgetData | null>(null);
  selectedWidgetRef.current = selectedWidget;

  const snappingLinesContainerRef = useRef<HTMLDivElement | null>(null);
  const horizontalSnappingLineRef = useRef<HTMLDivElement | null>(null);

  const customTextInputRef = useRef<HTMLInputElement | null>(null);

  const [designContainerHeight, setDesignContainerHeight] = useState<number>(0);

  const openBaseImageUpload = () => {
    if (!baseImageInputRef.current) {
      return;
    }

    baseImageInputRef.current.click();
  };

  const onBaseImageFileUploaded = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    if (!file.type.startsWith("image/")) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (!baseImageInputRef.current || !reader.result) {
        return;
      }

      props.setBaseImage(reader.result.toString());
    };

    reader.readAsDataURL(file);
  };

  const addQRCodeWidget = () => {
    const newWidget = new QrCodeAreaWidgetData();

    setSelectedWidget(newWidget);

    props.setAreaWidgets([...props.areaWidgets, newWidget]);
  };

  const addAvatarWidget = () => {
    const newWidget = new AvatarAreaWidgetData();

    setSelectedWidget(newWidget);

    props.setAreaWidgets([...props.areaWidgets, newWidget]);
  };

  const addTextWidget = () => {
    const lastAddedTextWidget = [...props.areaWidgets]
      .reverse()
      .filter((widget): widget is TextAreaWidgetData => widget.type === "text")
      .at(0);

    const newWidget = new TextAreaWidgetData(
      lastAddedTextWidget?.settings
        ? {
            ...lastAddedTextWidget.settings,
            text: "",
          }
        : undefined,
      designContainerHeight
    );

    setSelectedWidget(newWidget);

    props.setAreaWidgets([...props.areaWidgets, newWidget]);
  };

  const deleteWidget = (widget: AreaWidgetData) => {
    props.setAreaWidgets(props.areaWidgets.filter((w) => w !== widget));
  };

  const onWidgetDragging = (
    widget: AreaWidgetData,
    action: "start" | "move" | "stop"
  ) => {
    if (
      !snappingLinesContainerRef.current ||
      !horizontalSnappingLineRef.current
    ) {
      return;
    }

    if (action === "start") {
      snappingLinesContainerRef.current.classList.remove("invisible");
    } else if (action === "stop") {
      snappingLinesContainerRef.current.classList.add("invisible");

      horizontalSnappingLineRef.current.classList.add("invisible");

      setWidgetToSnapX(null);
    }

    if (action === "start" || action === "move") {
      const center = {
        x: widget.x + widget.width / 2,
        y: widget.y + widget.height / 2,
      };

      horizontalSnappingLineRef.current.classList.toggle(
        "invisible",
        center.x !== 50
      );

      setWidgetToSnapX(
        props.areaWidgets
          .filter((w) => w !== widget && w.x === widget.x)
          .at(0) ?? null
      );
    }
  };

  const onTextSettingTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!customTextInputRef.current) {
      return;
    }

    const customTextInputLabel = customTextInputRef.current
      .parentElement as HTMLLabelElement;

    if (selectedWidget && isTextAreaWidgetData(selectedWidget)) {
      const settings = selectedWidget.settings;

      if (event.target.value === "Custom") {
        settings.text = customTextInputRef.current.value;

        customTextInputLabel.classList.replace("hidden", "flex");
      } else {
        customTextInputRef.current.value = "";

        settings.text = event.target.value;

        customTextInputLabel.classList.replace("flex", "hidden");
      }

      const span = selectedWidget!.data.get("spanRef") as HTMLSpanElement;

      span.textContent = fillPlaceholders(
        settings.text,
        ExamplePlaceholderData
      );
    }
  };

  const onTextSettingWeightChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (selectedWidget && isTextAreaWidgetData(selectedWidget)) {
      const span = selectedWidget.data.get("spanRef") as HTMLSpanElement;

      selectedWidget.settings.weight = parseInt(event.target.value);

      span.style.fontWeight = event.target.value;
    }
  };

  useEffect(() => {
    if (!designContainerRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length === 0) {
        return;
      }

      const height = entries[0].contentRect.height;

      setDesignContainerHeight(height);

      propsRef.current.setAreaWidgets(propsRef.current.areaWidgets.map((w) => {
        if (!isTextAreaWidgetData(w)) {
          return w;
        }

        const textWidget = new TextAreaWidgetData(w.settings, height);
        textWidget.x = w.x;
        textWidget.y = w.y;
        textWidget.width = w.width;
        textWidget.height = w.height;

        return textWidget;
      }));
    });

    resizeObserver.observe(designContainerRef.current);

    return () => resizeObserver.disconnect();
  }, [designContainerRef.current]);

  return (
    <>
      {props.baseImage === null ? (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Button primary onClick={openBaseImageUpload}>
            Upload Image
          </Button>
          <input
            className="hidden"
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={onBaseImageFileUploaded}
            ref={baseImageInputRef}
          ></input>
        </div>
      ) : (
        <div className="flex w-full flex-row justify-between">
          <div
            className="flex flex-1 flex-col items-center justify-center"
            onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
              event.preventDefault();
            }}
          >
            <div
              className="relative min-h-0"
              ref={(ref) => {
                designContainerRef.current = ref;
                setDisplayDesign(true);
              }}
            >
              {displayDesign && (
                <>
                  <Image
                    src={props.baseImage}
                    className="max-h-full max-w-full select-none"
                    draggable="false"
                    onClick={() => {
                      setSelectedWidget(null);
                    }}
                  />

                  {props.areaWidgets.map((w, i) => (
                    <AreaWidget
                      key={i}
                      data={w}
                      onClick={() => setSelectedWidget(w)}
                      deleteSelf={() => {
                        deleteWidget(w);
                        setSelectedWidget(null);
                      }}
                      getOtherWidgets={() =>
                        props.areaWidgets.filter((widget) => widget !== w)
                      }
                      isSelected={w === selectedWidgetRef.current}
                      onDraggingAction={(action) => onWidgetDragging(w, action)}
                      boundsElement={designContainerRef.current!}
                    >
                      {w.content}
                    </AreaWidget>
                  ))}

                  <div
                    className="pointer-events-none invisible absolute left-0 top-0 h-full w-full"
                    ref={snappingLinesContainerRef}
                  >
                    <div
                      className="invisible absolute left-0 top-0 h-full w-1/2 border-r border-dashed border-blue-500"
                      ref={horizontalSnappingLineRef}
                    ></div>

                    {widgetToSnapX && (
                      <div
                        style={{
                          width: `${widgetToSnapX.x}%`,
                        }}
                        className="absolute left-0 top-0 h-full border-r border-dashed border-blue-500"
                      ></div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-2 border-l border-gray-300">
            {selectedWidget ? (
              <>
                {isAvatarAreaWidgetData(selectedWidget) && (
                  <div className="flex w-1/4 flex-col items-center gap-2">
                    <Checkbox
                      id="avatarSettingCircular"
                      label="Circular"
                      getValue={() => {
                        return selectedWidget.settings.circular;
                      }}
                      setValue={(value) => {
                        const settings = selectedWidget.settings;

                        settings.circular = value;

                        const image = selectedWidget.data.get(
                          "imgRef"
                        ) as HTMLImageElement;

                        image.style.borderRadius = settings.circular
                          ? "9999px"
                          : "0px";
                      }}
                    />
                  </div>
                )}
                {isTextAreaWidgetData(selectedWidget) && (
                  <div className="flex w-1/2 flex-col items-center gap-2">
                    <Checkbox
                      id="textSettingCentered"
                      className="h-7"
                      label="Centered"
                      getValue={() => {
                        return selectedWidget.settings.centered;
                      }}
                      setValue={(value) => {
                        const settings = selectedWidget.settings;

                        settings.centered = value;

                        const span = selectedWidget.data.get(
                          "spanRef"
                        ) as HTMLSpanElement;

                        span.style.textAlign = settings.centered
                          ? "center"
                          : "initial";
                      }}
                    />

                    <label
                      htmlFor="textSettingFontWeight"
                      className="flex h-7 w-full flex-row justify-center gap-2"
                    >
                      <span className="w-1/2 text-center">Font Weight</span>

                      <select
                        id="textSettingFontWeight"
                        className="w-1/2 border-2 border-gray-300"
                        defaultValue="400"
                        ref={(select) => {
                          if (!select) {
                            return;
                          }

                          select.value =
                            selectedWidget.settings.weight.toString();
                        }}
                        onChange={onTextSettingWeightChange}
                      >
                        <option value="300">Light</option>
                        <option value="400">Normal</option>
                        <option value="500">Medium</option>
                      </select>
                    </label>

                    <label
                      htmlFor="textSettingSize"
                      className="flex h-7 w-full flex-row justify-center gap-2"
                    >
                      <span className="w-1/2 text-center">Size</span>

                      <input
                        id="textSettingSize"
                        type="number"
                        className="w-1/2 border-2 border-gray-300"
                        step="0.1"
                        min="0"
                        ref={(input) => {
                          if (!input) {
                            return;
                          }

                          input.value = selectedWidget.settings.size.toString();
                        }}
                        onChange={(event) => {
                          const settings = selectedWidget.settings;

                          settings.size = parseFloat(event.target.value);

                          const span = selectedWidget.data.get(
                            "spanRef"
                          ) as HTMLSpanElement;

                          span.style.fontSize = `${(settings.size / 100) * designContainerHeight}px`;
                        }}
                      />
                    </label>

                    <label
                      htmlFor="textSettingColor"
                      className="flex h-7 w-full flex-row justify-center gap-2"
                    >
                      <span className="w-1/2 text-center">Color</span>

                      <input
                        className="h-7 w-1/2"
                        id="textSettingColor"
                        type="color"
                        ref={(input) => {
                          if (!input) {
                            return;
                          }

                          input.value = selectedWidget.settings.color;
                        }}
                        onChange={(event) => {
                          const settings = selectedWidget.settings;

                          settings.color = event.target.value;

                          const span = selectedWidget.data.get(
                            "spanRef"
                          ) as HTMLSpanElement;

                          span.style.color = event.target.value;
                        }}
                      />
                    </label>

                    <label
                      htmlFor="textSettingTextType"
                      className="flex h-7 w-full flex-row justify-center gap-2"
                    >
                      <span className="w-1/2 text-center">Content</span>

                      <select
                        id="textSettingTextType"
                        className="w-1/2 border-2 border-gray-300"
                        defaultValue=""
                        ref={(select) => {
                          if (!select) {
                            return;
                          }

                          const text = selectedWidget.settings.text;

                          if (text.length === 0) {
                            return;
                          }

                          select.value = text.startsWith("{") ? text : "Custom";
                        }}
                        onChange={onTextSettingTypeChange}
                      >
                        <option value="" disabled>
                          Select content
                        </option>
                        <option value="{DisplayName}">Name</option>
                        <option value="{GivenName}">First Name</option>
                        <option value="{Surname}">Last Name</option>
                        <option value="{MobilePhone}">Mobile Phone</option>
                        <option value="{BusinessPhone}">Business Phone</option>
                        <option value="{Mail}">E-Mail</option>
                        <option value="{JobTitle}">Job Title</option>
                        <option value="{Street}">Street</option>
                        <option value="{PostalCode}">Postal Code</option>
                        <option value="{City}">City</option>
                        <option value="{PostalCode} {City}">Postal Code and City</option>
                        <option value="{Country}">Country</option>
                        <option value="Custom">Custom Text</option>
                      </select>
                    </label>

                    <label
                      htmlFor="textSettingText"
                      className="hidden h-7 w-full flex-row justify-center gap-2"
                    >
                      <input
                        id="textSettingText"
                        type="text"
                        className="w-full border-2 border-gray-300"
                        ref={(input) => {
                          customTextInputRef.current = input;

                          if (!input) {
                            return;
                          }

                          input.value = selectedWidget.settings.text;

                          if (
                            input.value.length === 0 ||
                            input.value.startsWith("{")
                          ) {
                            input.parentElement!.classList.replace(
                              "flex",
                              "hidden"
                            );

                            input.value = "";
                          } else {
                            input.parentElement!.classList.replace(
                              "hidden",
                              "flex"
                            );
                          }
                        }}
                        onChange={(event) => {
                          const settings = selectedWidget.settings;

                          settings.text = event.target.value;

                          const span = selectedWidget.data.get(
                            "spanRef"
                          ) as HTMLSpanElement;

                          span.textContent = event.target.value;
                        }}
                      />
                    </label>
                  </div>
                )}

                <Button
                  primary
                  className="w-full"
                  onClick={() => {
                    deleteWidget(selectedWidget);
                    setSelectedWidget(null);
                  }}
                >
                  Delete Element
                </Button>
              </>
            ) : (
              <>
                <Button
                  primary
                  className="w-full"
                  onClick={addQRCodeWidget}
                  disabled={props.areaWidgets.some((w) => w.type === "qr")}
                >
                  Add QR Code
                </Button>

                <Button
                  primary
                  className="w-full"
                  onClick={addAvatarWidget}
                  disabled={props.areaWidgets.some((w) => w.type === "avatar")}
                >
                  Add Avatar
                </Button>

                <Button primary className="w-full" onClick={addTextWidget}>
                  Add Text
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
