import { Button } from "@fluentui/react-northstar";
import { useGraph } from "@microsoft/teamsfx-react";
import { useContext, useEffect, useState } from "react";

import { useLiveQuery } from "dexie-react-hooks";
import { CardStructure } from "../models/CardStructure";
import { UserProfile } from "../models/UserProfile";
import { bccDb } from "../services/BusinessCardCreatorDatabaseService";
import { getCardStructures } from "../services/CardConfigService";
import { getUserProfileAndPicture } from "../services/TeamsService";
import { convertDataToVcard } from "../services/VCardService";
import { DesignNumberContext } from "./App";
import { Card } from "./card-component/Card";
import { TeamsFxContext } from "./Context";

export const FullViewTab = () => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [, setProfilePictureDimensions] = useState([0, 0]);

  const { teamsfx } = useContext(TeamsFxContext);

  const { loading, error, data, reload } = useGraph(
    async (graph) => {
      await getUserProfileAndPicture(
        graph,
        setUserProfile,
        setProfilePictureDimensions,
        setPhotoUrl
      );

      return true;
    },
    { scope: ["User.Read"], teamsfx }
  );

  const { designNumber, setDesignNumber } = useContext(DesignNumberContext);

  const [card, setCard] = useState<CardStructure | null>(null);

  const cards = useLiveQuery(() => bccDb.cards.toArray());

  useEffect(() => {
    if (!cards) {
      return;
    }

    if (designNumber >= cards.length) {
      setDesignNumber(0);
    }

    setCard(cards[designNumber]);
  }, [designNumber, setDesignNumber, cards]);

  useEffect(() => {
    getCardStructures();
  });

  const onStorageUpdate = (event: StorageEvent) => {
    if (event.key === "designNumber" && event.newValue) {
      setDesignNumber(parseInt(event.newValue));
    }
  };

  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);

    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  });

  return (
    <div className="flex h-full flex-col items-center">
      {!error && !data && (
        <div className="pt-2">
          <Button
            primary
            content="Authorize"
            disabled={loading}
            onClick={reload}
          />
        </div>
      )}

      {!loading && error && (
        <div className="pt-2">
          Failed to read your profile. Please try again later. Error: {JSON.stringify(error)}
        </div>
      )}

      {!loading && data && (
        <>
          {userProfile === null ? (
            <div className="pt-2">
              Failed to load your profile. Please try again later.
            </div>
          ) : (
            <>
              {(cards === undefined || cards.length === 0) ? (
                <p>
                  Couldn't load cards. Please try again later.
                </p>
              ) : null}

              {card && (
                <div className="h-full">
                  <Card
                    placeholderData={
                      new Map([
                        ["{VCard}", convertDataToVcard(userProfile)],
                        ["{PhotoUrl}", photoUrl],
                        ["{DisplayName}", userProfile.displayName],
                        ["{GivenName}", userProfile.givenName],
                        ["{Surname}", userProfile.surname],
                        ["{MobilePhone}", userProfile.mobilePhone],
                        ["{BusinessPhone}", userProfile.businessPhones],
                        ["{Mail}", userProfile.mail],
                        ["{JobTitle}", userProfile.jobTitle],
                        ["{Street}", userProfile.streetAddress],
                        ["{PostalCode}", userProfile.postalCode],
                        ["{City}", userProfile.city],
                        ["{Country}", userProfile.country],
                      ])
                    }
                    structure={card}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}

      
    </div>
  );
};
