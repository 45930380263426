import { Button } from "@fluentui/react-northstar";
import { useGraph } from "@microsoft/teamsfx-react";
import { useContext, useState } from "react";
import { UserProfile } from "../models/UserProfile";
import { getUserProfileAndPicture } from "../services/TeamsService";
import { TeamsFxContext } from "./Context";
import { Design } from "./design-component/Design";
import { UserCredentials } from "./user-credentials-component/UserCredentials";

export const DesignerTab = () => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [profilePictureDimensions, setProfilePictureDimensions] = useState([
    0, 0,
  ]);

  const { teamsfx } = useContext(TeamsFxContext);

  const { loading, error, data, reload } = useGraph(
    async (graph) => {
      await getUserProfileAndPicture(
        graph,
        setUserProfile,
        setProfilePictureDimensions,
        setPhotoUrl
      );

      return true;
    },
    { scope: ["User.Read"], teamsfx }
  );

  enum MenuItems {
    Editor = "Editor",
    Design = "Design",
  }

  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItems>(
    MenuItems.Editor
  );

  function renderSelectedMenuItem() {
    switch (selectedMenuItem) {
      case MenuItems.Editor:
        return (
          <UserCredentials
            profileData={userProfile!}
            photoUrl={photoUrl ?? undefined}
            setProfileData={setUserProfile}
            setPhotoUrl={setPhotoUrl}
            profilePictureDimensions={profilePictureDimensions}
          />
        );
      case MenuItems.Design:
        return (
          <Design
            profileData={userProfile!}
            photoUrl={photoUrl ?? undefined}
            profilePictureDimensions={profilePictureDimensions}
          />
        );
    }
  }

  return (
    <>
      {!error && !data && (
        <div className="flex h-full flex-col items-center pt-2">
          <Button
            primary
            content="Authorize"
            disabled={loading}
            onClick={reload}
          />
        </div>
      )}

      {!loading && error && (
        <div className="flex h-full flex-col items-center pt-2">
          Failed to read your profile. Please try again later. Error: {JSON.stringify(error)}
        </div>
      )}

      {!loading && data && (
        <>
          {userProfile === null ? (
            <div className="flex h-full flex-col items-center pt-2">
              Failed to load your profile. Please try again later.
            </div>
          ) : (
            <div>
            {/* Mobile Design */}
              <div className="flex flex-col py-2 lg:hidden">
                <div className="mb-2 flex flex-row justify-center gap-9">
                  {Object.entries(MenuItems).map(([value, key]) => (
                    <Button
                      key={key}
                      onClick={() => setSelectedMenuItem(key)}
                      primary
                      content={value}
                    />
                  ))}
                </div>

                <div className="flex flex-row justify-center">
                  <div className="w-1/6"></div>
                  {renderSelectedMenuItem()}
                  <div className="w-1/6"></div>
                </div>
              </div>

              {/* Desktop Design */}
              <div className="hidden py-2 lg:block">
                <div className="flex flex-row justify-center gap-9">
                  <div className="flex basis-1/3 justify-center">
                    <UserCredentials
                      profileData={userProfile}
                      photoUrl={photoUrl ?? undefined}
                      setProfileData={setUserProfile}
                      setPhotoUrl={setPhotoUrl}
                      profilePictureDimensions={profilePictureDimensions}
                    />
                  </div>

                  <div className="flex basis-1/3 justify-center">
                    <Design
                      profileData={userProfile}
                      photoUrl={photoUrl ?? undefined}
                      profilePictureDimensions={profilePictureDimensions}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
