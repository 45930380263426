import { CardStructure } from "../models/CardStructure";
import { bccDb } from "./BusinessCardCreatorDatabaseService";

var cards: CardStructure[] = [];

export const getCardStructures = async () => {
  if (cards.length === 0) {
    cards = await bccDb.cards.toArray();

    if (cards.length === 0) {
      cards = await getDefaultCards();
      await saveCards();
    }
  }

  return cards;
};

const getDefaultCards = async () => {
  const bccEnvironment = process.env.REACT_APP_BCC_RESSOURCE;

  try {
    const response = await fetch(`./${bccEnvironment}/cards.json`);

    const json = await response.json();

    return json as CardStructure[];
  } catch (error) {
    console.error("Error loading default cards", error);
    return [];
  }
};

export const addCardStructure = async (card: CardStructure) => {
  cards.push(card);

  await saveCards();
};

export const addCardStructures = async (cardStructures: CardStructure[]) => {
  cards = cards.concat(cardStructures);

  await saveCards();
};

export const setCardStructure = async (index: number, card: CardStructure) => {
  card.id = cards[index].id;
  cards[index] = card;

  await saveCards();
};

export const setCards = async (cardStructures: CardStructure[]) => {
  cards = cardStructures;

  await saveCards();
};

const saveCards = async () => {
  await bccDb.transaction("readwrite", bccDb.cards, async () => {
    await bccDb.cards.bulkPut(cards, { allKeys: true }).then((ids) => {
      ids.forEach((id, i) => {
        cards[i].id = id;
      });
    });

    await bccDb.cards.each(async (card) => {
      if (cards.find((c) => c.id === card.id) === undefined) {
        await bccDb.cards.delete(card.id!);
      }
    });
  });
};
