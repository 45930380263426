import { CardStructure } from "../../models/CardStructure";
import { Image } from "@fluentui/react-northstar";
import { QRCodeCanvas } from "qrcode.react";
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  PlaceholderData,
  fillPlaceholders,
} from "../../services/CardTextPlaceholderService";

export const Card = forwardRef(
  (
    props: {
      structure: CardStructure;
      placeholderData: PlaceholderData;
    },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const containerDivRef = useRef<HTMLDivElement | null>(null);
    const backgroundImageRef = useRef<HTMLImageElement | null>(null);
    const dataDivRef = useRef<HTMLDivElement | null>(null);
    const [size, setSize] = useState<{ width: number; height: number }>({
      width: 0,
      height: 0,
    });

    useEffect(() => {
      if (!containerDivRef.current) {
        return;
      }

      const resizeObserver = new ResizeObserver((entries) => {
        if (entries.length === 0) {
          return;
        }

        setSize({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });

        if (dataDivRef.current && backgroundImageRef.current) {
          dataDivRef.current.style.height =
            backgroundImageRef.current.height.toString();
        }
      });

      resizeObserver.observe(containerDivRef.current);

      return () => resizeObserver.disconnect();
    }, []);

    return (
      <div className="h-full min-h-0" ref={containerDivRef}>
        <div className="relative h-full min-h-0" ref={ref}>
          <Image
            className="max-h-full max-w-full select-none"
            src={props.structure.baseImage}
            ref={backgroundImageRef}
          />

          <div ref={dataDivRef} className="absolute left-0 top-0 h-full w-full">
            {props.structure.qrCode && (
              <QRCodeCanvas
                value={fillPlaceholders(
                  props.structure.qrCode.value,
                  props.placeholderData
                )}
                size={Math.floor(
                  (props.structure.qrCode.size / 100) * size.width
                )}
                className="absolute"
                style={{
                  left: `${props.structure.qrCode.position[0]}%`,
                  top: `${props.structure.qrCode.position[1]}%`,
                }}
              ></QRCodeCanvas>
            )}

            {props.structure.profilePicture && (
              <Image
                circular={props.structure.profilePicture.circular}
                src={fillPlaceholders(
                  props.structure.profilePicture.image,
                  props.placeholderData
                )}
                width={`${props.structure.profilePicture.size}%`}
                height={`${
                  props.structure.profilePicture.size *
                  (size.width / size.height)
                }%`}
                className="absolute aspect-square select-none"
                style={{
                  left: `${props.structure.profilePicture.position[0]}%`,
                  top: `${props.structure.profilePicture.position[1]}%`,
                }}
                draggable={false}
              />
            )}

            {props.structure.texts &&
              props.structure.texts.map((text, index) => (
                <span
                  key={index}
                  className={`absolute ${
                    text.centered ? "text-center" : ""
                  } select-none overflow-hidden font-rubik leading-none`}
                  style={{
                    width: `${text.size[0]}%`,
                    height: `${text.size[1]}%`,
                    left: `${text.position[0]}%`,
                    top: `${text.position[1]}%`,
                    color: text.color,
                    fontSize: `${(text.textSize / 100) * size.height}px`,
                    fontWeight: text.fontWeight,
                  }}
                >
                  {fillPlaceholders(text.text, props.placeholderData)}
                </span>
              ))}
          </div>
        </div>
      </div>
    );
  }
);
