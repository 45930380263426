import { Loader, Provider, teamsTheme } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { useTeamsFx } from "@microsoft/teamsfx-react";
import {
  createContext,
  useMemo,
  useState
} from "react";
import { Redirect, Route, HashRouter as Router } from "react-router-dom";

import { TeamsFxContext } from "./Context";
import { DesignerTab } from "./Designer";
import { FullViewTab } from "./FullView";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
const getDesignNumber = () => {
  if (window.localStorage.getItem("designNumber")) {
    return parseInt(window.localStorage.getItem("designNumber")!);
  } else {
    window.localStorage.setItem("designNumber", "1");
    return 1;
  }
};

export const DesignNumberContext = createContext<{
  designNumber: number;
  setDesignNumber: (designNumber: number) => void;
}>({
  designNumber: getDesignNumber(),
  setDesignNumber: () => {},
});

export default function App() {
  const { loading, error, theme, themeString, teamsfx } = useTeamsFx({
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
    clientId: process.env.REACT_APP_CLIENT_ID!,
  });

  const [designNumber, _setDesignNumber] = useState<number>(getDesignNumber());

  const setDesignNumber = (designNumber: number) => {
    _setDesignNumber(designNumber);
    window.localStorage.setItem("designNumber", designNumber.toString());
  };

  const value = useMemo(
    () => ({ designNumber: designNumber, setDesignNumber }),
    [designNumber]
  );

  microsoftTeams.initialize(() => {
    microsoftTeams.registerOnThemeChangeHandler((theme) => {
      window.location.reload();
    });

    microsoftTeams.appInitialization.notifySuccess();
  });

  microsoftTeams.getContext((context: microsoftTeams.Context) => {
    if (context.theme === "default") {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }
  });

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsfx }}>
      <Provider
        theme={theme || teamsTheme}
        styles={{ backgroundColor: "#eeeeee" }}
      >
        <Router>
          <Route exact path="/">
            <Redirect to="/FullView" />
          </Route>
          {loading ? (
            <Loader style={{ margin: 100 }} />
          ) : (
            <>
              {!loading && error && (
                <div>Failed init TeamsFx. Please try again later. Error: {JSON.stringify(error)}</div>
              )}

              {!loading && teamsfx && (
                <DesignNumberContext.Provider value={value}>
                  <Route exact path="/FullView" component={FullViewTab} />
                  <Route exact path="/Designer" component={DesignerTab} />
                </DesignNumberContext.Provider>
              )}
            </>
          )}
        </Router>
      </Provider>
    </TeamsFxContext.Provider>
  );
}
