import { useEffect, useRef, useState } from "react";
import { AreaWidgetData } from "../../models/AreaWidgetData";
import { AreaWidgetSizeConstraints } from "../../enums/AreaWidgetSizeConstraints";
import { clamp } from "../../services/MathService";

type AreaWidgetProps = {
  data: AreaWidgetData;
  onClick: () => void;
  onDraggingAction: (action: "start" | "move" | "stop") => void;
  deleteSelf: () => void;
  getOtherWidgets: () => AreaWidgetData[];
  isSelected: boolean;
  boundsElement: HTMLElement;
};

type ResizeKnobPositions =
  | "tl"
  | "tm"
  | "tr"
  | "mr"
  | "br"
  | "bm"
  | "bl"
  | "ml";

const snappingThresholdPercentage = 2;

export const AreaWidget = (props: React.PropsWithChildren<AreaWidgetProps>) => {
  const widgetContainerRef = useRef<HTMLDivElement | null>(null);

  const [dragLastPosition, setDragLastPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const dragLastPositionRef = useRef(dragLastPosition);
  dragLastPositionRef.current = dragLastPosition;

  const [resizePosition, setResizePosition] =
    useState<ResizeKnobPositions | null>(null);
  const resizePositionRef = useRef(resizePosition);
  resizePositionRef.current = resizePosition;

  const propsRef = useRef(props);
  propsRef.current = props;

  const [ignoreHorizontalMoves, setIgnoreHorizontalMoves] =
    useState<boolean>(false);
  const ignoreHorizontalMovesRef = useRef(ignoreHorizontalMoves);
  ignoreHorizontalMovesRef.current = ignoreHorizontalMoves;

  const doSnapping = (move: { x: number; y: number }) => {
    const center = {
      x: propsRef.current.data.x + propsRef.current.data.width / 2,
      y: propsRef.current.data.y + propsRef.current.data.height / 2,
    };

    //check if the center of our widget is in the vicinity of the center of the card and if mouse movement is towards our snapping point and not away
    if (
      Math.abs(50 - center.x) < snappingThresholdPercentage &&
      ((center.x < 50 && move.x > 0) || (center.x > 50 && move.x < 0))
    ) {
      propsRef.current.data.x = 50 - propsRef.current.data.width / 2;

      setIgnoreHorizontalMoves(true);

      setTimeout(() => {
        setIgnoreHorizontalMoves(false);
      }, 250);
    }

    const otherWidgets = propsRef.current.getOtherWidgets();

    for (let i = 0; i < otherWidgets.length; i++) {
      const widget = otherWidgets[i];

      if (
        Math.abs(widget.x - propsRef.current.data.x) <
          snappingThresholdPercentage &&
        ((propsRef.current.data.x < widget.x && move.x > 0) ||
          (propsRef.current.data.x > widget.x && move.x < 0))
      ) {
        propsRef.current.data.x = widget.x;

        setIgnoreHorizontalMoves(true);

        setTimeout(() => {
          setIgnoreHorizontalMoves(false);
        }, 250);
      }
    }
  };

  const handleMouseMoveResize = (event: MouseEvent) => {
    const bounds = props.boundsElement.getBoundingClientRect();

    const difX = event.clientX - dragLastPositionRef.current!.x;
    let relDifX = (difX / bounds.width) * 100;

    const difY = event.clientY - dragLastPositionRef.current!.y;
    let relDifY = (difY / bounds.height) * 100;

    const handleHorizontalDrag = (change: "x" | "width") => {
      if (change === "x") {
        if (
          propsRef.current.data.sizeConstraint ===
            AreaWidgetSizeConstraints.Square &&
          propsRef.current.data.height === 100 &&
          relDifX < 0
        ) {
          return;
        }

        propsRef.current.data.x += relDifX;
        if (propsRef.current.data.x < 0) {
          relDifX -= propsRef.current.data.x;
          propsRef.current.data.x = 0;
        }

        propsRef.current.data.width -= relDifX;
        if (propsRef.current.data.width < 0) {
          propsRef.current.data.x += propsRef.current.data.width;
          propsRef.current.data.width = 0;
        }

        if (
          propsRef.current.data.sizeConstraint ===
          AreaWidgetSizeConstraints.Square
        ) {
          propsRef.current.data.height -=
            relDifX * (bounds.width / bounds.height);
        }
      } else {
        if (
          propsRef.current.data.sizeConstraint ===
            AreaWidgetSizeConstraints.Square &&
          propsRef.current.data.height === 100 &&
          relDifX > 0
        ) {
          return;
        }

        propsRef.current.data.width += relDifX;
        if (propsRef.current.data.x + propsRef.current.data.width > 100) {
          propsRef.current.data.width = 100 - propsRef.current.data.x;
          relDifX = 0;
        }

        if (
          propsRef.current.data.sizeConstraint ===
          AreaWidgetSizeConstraints.Square
        ) {
          propsRef.current.data.height +=
            relDifX * (bounds.width / bounds.height);
        }
      }
    };

    const handleVerticalDrag = (change: "y" | "height") => {
      if (change === "y") {
        if (
          propsRef.current.data.sizeConstraint ===
            AreaWidgetSizeConstraints.Square &&
          propsRef.current.data.width === 100 &&
          relDifY < 0
        ) {
          return;
        }

        propsRef.current.data.y += relDifY;
        if (propsRef.current.data.y < 0) {
          relDifY -= propsRef.current.data.y;
          propsRef.current.data.y = 0;
        }

        propsRef.current.data.height -= relDifY;
        if (propsRef.current.data.height < 0) {
          propsRef.current.data.y += propsRef.current.data.height;
          propsRef.current.data.height = 0;
        }

        if (
          propsRef.current.data.sizeConstraint ===
          AreaWidgetSizeConstraints.Square
        ) {
          propsRef.current.data.width -=
            relDifY * (bounds.height / bounds.width);
        }
      } else {
        if (
          propsRef.current.data.sizeConstraint ===
            AreaWidgetSizeConstraints.Square &&
          propsRef.current.data.width === 100 &&
          relDifY > 0
        ) {
          return;
        }

        propsRef.current.data.height += relDifY;
        if (propsRef.current.data.y + propsRef.current.data.height > 100) {
          propsRef.current.data.height = 100 - propsRef.current.data.y;
          relDifY = 0;
        }

        if (
          propsRef.current.data.sizeConstraint ===
          AreaWidgetSizeConstraints.Square
        ) {
          propsRef.current.data.width +=
            relDifY * (bounds.height / bounds.width);
        }
      }
    };

    switch (resizePositionRef.current) {
      case "tl":
        handleHorizontalDrag("x");
        handleVerticalDrag("y");
        break;
      case "tm":
        handleVerticalDrag("y");
        break;
      case "tr":
        handleHorizontalDrag("width");
        handleVerticalDrag("y");
        break;
      case "mr":
        handleHorizontalDrag("width");
        break;
      case "br":
        handleHorizontalDrag("width");
        handleVerticalDrag("height");
        break;
      case "bm":
        handleVerticalDrag("height");
        break;
      case "bl":
        handleHorizontalDrag("x");
        handleVerticalDrag("height");
        break;
      case "ml":
        handleHorizontalDrag("x");
        break;
      default:
        break;
    }

    clampPosition();

    setDragLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMovePosition = (event: MouseEvent) => {
    const bounds = props.boundsElement.getBoundingClientRect();

    const difX = ignoreHorizontalMovesRef.current
      ? 0
      : event.clientX - dragLastPositionRef.current!.x;
    const difY = event.clientY - dragLastPositionRef.current!.y;

    propsRef.current.data.x += (difX / bounds.width) * 100;
    propsRef.current.data.y += (difY / bounds.height) * 100;

    doSnapping({
      x: difX,
      y: difY,
    });

    clampPosition();

    propsRef.current.onDraggingAction("move");

    setDragLastPosition({ x: event.clientX, y: event.clientY });
  };

  const onMouseMove = (event: MouseEvent) => {
    if (!dragLastPositionRef.current) {
      return;
    }

    if (resizePositionRef.current) {
      handleMouseMoveResize(event);
    } else {
      handleMouseMovePosition(event);
    }
  };

  const onMouseUp = () => {
    if (!dragLastPositionRef.current) {
      return;
    }

    props.onDraggingAction("stop");

    setResizePosition(null);
    setDragLastPosition(null);
    setIgnoreHorizontalMoves(false);
  };

  const applyConstraint = () => {
    switch (propsRef.current.data.sizeConstraint) {
      case AreaWidgetSizeConstraints.None:
        break;
      case AreaWidgetSizeConstraints.Square:
        if (
          propsRef.current.data.width <= 0 &&
          propsRef.current.data.height <= 0
        ) {
          propsRef.current.data.width = propsRef.current.data.height = 10;
        }

        const bounds = props.boundsElement.getBoundingClientRect();

        if (propsRef.current.data.width > propsRef.current.data.height) {
          propsRef.current.data.height =
            propsRef.current.data.width * (bounds.width / bounds.height);
        } else {
          propsRef.current.data.width =
            propsRef.current.data.height * (bounds.height / bounds.width);
        }

        break;
    }

    clampPosition();
  };

  const clampPosition = () => {
    propsRef.current.data.width = clamp(propsRef.current.data.width, 0, 100);
    propsRef.current.data.height = clamp(propsRef.current.data.height, 0, 100);

    propsRef.current.data.x = clamp(
      propsRef.current.data.x,
      0,
      100 - propsRef.current.data.width
    );
    propsRef.current.data.y = clamp(
      propsRef.current.data.y,
      0,
      100 - propsRef.current.data.height
    );

    updateStyle();
  };

  const updateStyle = () => {
    if (!widgetContainerRef.current) {
      return;
    }

    const style = widgetContainerRef.current.style;
    style.left = `${propsRef.current.data.x}%`;
    style.top = `${propsRef.current.data.y}%`;
    style.width = `${propsRef.current.data.width}%`;
    style.height = `${propsRef.current.data.height}%`;
    style.outline = propsRef.current.isSelected
      ? "1px solid red"
      : "1px dashed red";
    style.outlineOffset = "1px";
    style.cursor = propsRef.current.isSelected ? "move" : "auto";
  };

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    applyConstraint();

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  useEffect(() => {
    updateStyle();
  }, [propsRef.current.isSelected, propsRef.current.data]);

  const onResizeKnobMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.target as HTMLDivElement;

    setResizePosition(element.dataset.pos! as ResizeKnobPositions);
    setDragLastPosition({ x: event.clientX, y: event.clientY });

    //Prevent event from bubbling up to our parent div that handles moving
    event.stopPropagation();
  };

  return (
    <div
      ref={widgetContainerRef}
      className="absolute bg-gray-400/50"
      onClick={props.onClick}
      onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
        if (!propsRef.current.isSelected) {
          return;
        }

        props.onDraggingAction("start");

        setResizePosition(null);
        setDragLastPosition({ x: event.clientX, y: event.clientY });
      }}
      onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (propsRef.current.isSelected && event.key === "Delete") {
          props.deleteSelf();
        }
      }}
      tabIndex={-1}
    >
      {props.children}

      {props.isSelected && (
        <>
          {/* Top Left */}
          <div
            data-pos="tl"
            className="absolute -left-[5px] -top-[5px] h-[10px] w-[10px] cursor-nwse-resize rounded-full bg-[#dbdbdb] hover:bg-[#4742d4]"
            onMouseDown={onResizeKnobMouseDown}
          ></div>

          {/* Top Mid */}
          <div
            data-pos="tm"
            className="absolute left-[calc(50%-5px)] -top-[5px] h-[10px] w-[10px] cursor-ns-resize rounded-full bg-[#dbdbdb] hover:bg-[#4742d4]"
            onMouseDown={onResizeKnobMouseDown}
          ></div>

          {/* Top Right */}
          <div
            data-pos="tr"
            className="absolute -right-[5px] -top-[5px] h-[10px] w-[10px] cursor-nesw-resize rounded-full bg-[#dbdbdb] hover:bg-[#4742d4]"
            onMouseDown={onResizeKnobMouseDown}
          ></div>

          {/* Mid Right */}
          <div
            data-pos="mr"
            className="absolute -right-[5px] top-[calc(50%-5px)] h-[10px] w-[10px] cursor-ew-resize rounded-full bg-[#dbdbdb] hover:bg-[#4742d4]"
            onMouseDown={onResizeKnobMouseDown}
          ></div>

          {/* Bottom Right */}
          <div
            data-pos="br"
            className="absolute -right-[5px] -bottom-[5px] h-[10px] w-[10px] cursor-nwse-resize rounded-full bg-[#dbdbdb] hover:bg-[#4742d4]"
            onMouseDown={onResizeKnobMouseDown}
          ></div>

          {/* Bottom Mid */}
          <div
            data-pos="bm"
            className="absolute left-[calc(50%-5px)] -bottom-[5px] h-[10px] w-[10px] cursor-ns-resize rounded-full bg-[#dbdbdb] hover:bg-[#4742d4]"
            onMouseDown={onResizeKnobMouseDown}
          ></div>

          {/* Bottom Left */}
          <div
            data-pos="bl"
            className="absolute -left-[5px] -bottom-[5px] h-[10px] w-[10px] cursor-nesw-resize rounded-full bg-[#dbdbdb] hover:bg-[#4742d4]"
            onMouseDown={onResizeKnobMouseDown}
          ></div>

          {/* Mid Left */}
          <div
            data-pos="ml"
            className="absolute -left-[5px] top-[calc(50%-5px)] h-[10px] w-[10px] cursor-ew-resize rounded-full bg-[#dbdbdb] hover:bg-[#4742d4]"
            onMouseDown={onResizeKnobMouseDown}
          ></div>
        </>
      )}
    </div>
  );
};
