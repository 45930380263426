import { Image } from "@fluentui/react-northstar";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useRef } from "react";
import { UserProfile } from "../../models/UserProfile";
import { convertDataToVcard } from "../../services/VCardService";
import { InputField } from "../input-field-component/InputField";

export const UserCredentials = (props: {
  profileData: UserProfile;
  photoUrl?: string;
  setProfileData: (data: UserProfile | null) => void;
  setPhotoUrl: (url: string | null) => void;
  profilePictureDimensions: number[];
}) => {
  const profilePictureImgRef = useRef<HTMLImageElement | null>(null);
  const profilePictureInputRef = useRef<HTMLInputElement | null>(null);

  const handlePictureChange = () => {
    profilePictureInputRef.current?.click();
  };

  const onProfilePictureFileUploaded = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      readImage(event.target.files[0]);
    }
  };

  const readImage = (file: File) => {
    if (file.type && !file.type.startsWith("image/")) {
      console.log("File is not an image.", file.type, file);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (!profilePictureImgRef.current || !reader.result) {
        return;
      }

      profilePictureImgRef.current.onload = () => {
        window.localStorage.setItem(
          "profilePicture",
          JSON.stringify({
            width: profilePictureImgRef.current?.width,
            height: profilePictureImgRef.current?.height,
            src: profilePictureImgRef.current?.src,
          })
        );

        props.setPhotoUrl(profilePictureImgRef.current!.src);
      };

      profilePictureImgRef.current.src = reader.result.toString();
    };

    reader.readAsDataURL(file);
  };

  const validateKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    //whole string has to consist of one or more numbers, plus, brackets or spaces
    const phoneNumberRegex = /^[0-9+() ]+$/gi;

    const nativeEvent = event.nativeEvent as any;
    const inputElement = nativeEvent.target as HTMLInputElement;

    switch (nativeEvent.inputType) {
      case "deleteContentBackward":
      case "historyUndo":
        return true;
      case "insertFromPaste":
        if (!phoneNumberRegex.test(inputElement.value)) {
          inputElement.value = "";
          return false;
        }
        return true;
      case "insertText":
        if (!phoneNumberRegex.test(nativeEvent.data)) {
          if (inputElement.value) {
            inputElement.value = inputElement.value.slice(0, -1);
          }

          return false;
        }

        return true;
      default:
        return false;
    }
  };

  const handleChangeOfUserProfile = (
    event: React.ChangeEvent<HTMLInputElement>,
    propertyName: keyof UserProfile,
    validator?: (event: React.ChangeEvent<HTMLInputElement>) => boolean
  ) => {
    if (validator && !validator(event)) {
      return;
    }

    props.setProfileData({
      ...props.profileData,
      [propertyName]: event.target.value,
    });

    window.localStorage.setItem(propertyName, event.target.value);
  };

  useEffect(() => {
    if (!profilePictureImgRef.current) {
      return;
    }

    profilePictureImgRef.current.src = props.photoUrl ?? "";
  }, [profilePictureImgRef, props.photoUrl]);

  return (
    <div className="flex flex-col items-center justify-start gap-3">
      {/* Welcome Text */}
      <div className="flex w-full flex-row gap-8 rounded-xl bg-[#484494] px-8 py-4 text-white shadow-blur-lg shadow-[#b3b3b3]">
        <div className="flex w-1/3 items-center justify-center">
          <Image
            ref={profilePictureImgRef}
            className="m-3 aspect-square border-4 border-solid border-white align-top cursor-pointer"
            circular
            src={props.photoUrl}
            onClick={handlePictureChange}
          />

          <input
            className="hidden"
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={onProfilePictureFileUploaded}
            ref={profilePictureInputRef}
          ></input>
        </div>
        <div className="flex flex-col justify-center">
          <p className="mb-4 break-normal text-3xl">
            Hi {props.profileData.givenName},
          </p>
          <p className="break-normal">
            here you can create your personal digital business card, save it as
            an image and transfer it to your smartphone.
          </p>
        </div>
      </div>

      {/* User Credentials */}
      <div className="w-full rounded-xl px-8 py-4 shadow-blur-lg shadow-[#b3b3b3]">
        <div className="prose dark:prose-invert">
          <h2 className="mb-2">Personal details</h2>
          <p className="mb-2">
            A vCard QR code is generated from your data.
          </p>
        </div>

        <div className="flex flex-row flex-wrap justify-between gap-4">
          <div className="prose dark:prose-invert prose-p:m-0 prose-p:leading-snug prose-a:m-0 prose-a:leading-snug">
            <p>{props.profileData.companyName}</p>
            <p>{props.profileData.displayName}</p>
            <p>{props.profileData.mail}</p>
            <p>{props.profileData.streetAddress}</p>
            <p>
              {props.profileData.postalCode} {props.profileData.city}
            </p>
            <p>{props.profileData.country}</p>
          </div>

          <QRCodeCanvas
            value={convertDataToVcard(props.profileData)}
            size={250}
          ></QRCodeCanvas>
        </div>

        <form className="mt-4">
          <InputField
            id="companyNameInput"
            label="Company Name"
            defaultValue={props.profileData.companyName ?? ""}
            type="text"
            onChange={(event) =>
              handleChangeOfUserProfile(event, "companyName")
            }
          />

          <InputField
            id="jobTitleInput"
            label="Job Title"
            defaultValue={props.profileData.jobTitle}
            type="text"
            onChange={(event) => handleChangeOfUserProfile(event, "jobTitle")}
          />

          <InputField
            id="businessPhoneInput"
            label="Business Phone"
            defaultValue={props.profileData.businessPhones ?? ""}
            type="tel"
            onChange={(event) =>
              handleChangeOfUserProfile(event, "businessPhones", validateKey)
            }
          />

          <InputField
            id="mobilePhoneInput"
            label="Mobile Phone"
            defaultValue={props.profileData.mobilePhone ?? ""}
            type="tel"
            onChange={(event) =>
              handleChangeOfUserProfile(event, "mobilePhone", validateKey)
            }
          />

          <div className="mt-1 border-t-2 border-solid border-[#b3b3b3] p-3">
            <div className="text-center">
              <span className="mb-2 inline-block text-lg font-bold">
                Address
              </span>
            </div>

            <InputField
              id="streetAddressInput"
              label="Street Address"
              defaultValue={props.profileData.streetAddress ?? ""}
              type="text"
              onChange={(event) =>
                handleChangeOfUserProfile(event, "streetAddress")
              }
            />

            <div className="w-full flex-row gap-2 lg:flex">
              <InputField
                id="cityInput"
                className="lg:basis-2/3"
                label="City"
                defaultValue={props.profileData.city ?? ""}
                type="text"
                onChange={(event) => handleChangeOfUserProfile(event, "city")}
              />

              <InputField
                id="postalCodeInput"
                label="Postal Code"
                defaultValue={props.profileData.postalCode ?? ""}
                type="text"
                onChange={(event) =>
                  handleChangeOfUserProfile(event, "postalCode")
                }
              />
            </div>

            <InputField
              id="countryInput"
              label="Country"
              defaultValue={props.profileData.country ?? ""}
              type="text"
              onChange={(event) => handleChangeOfUserProfile(event, "country")}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
