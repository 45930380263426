import { UserProfile } from "../models/UserProfile";

export const convertDataToVcard = (profileData: UserProfile) => {
  //Specification: https://datatracker.ietf.org/doc/html/rfc6350

  let vcard = "BEGIN:VCARD\n";
  vcard += "VERSION:3.0\n";

  //Structured name
  vcard += `N:${profileData.surname};${profileData.givenName}\n`;

  //Formatted name
  vcard += `FN:${profileData.displayName}\n`;

  //Company/Organization
  if (profileData.companyName) {
    vcard += `ORG;TYPE=work:${profileData.companyName}\n`;
  }

  //Title
  vcard += `TITLE:${profileData.jobTitle}\n`;

  //Address
  vcard += `ADR;TYPE=work:;;${profileData.streetAddress ?? ""};${
    profileData.city ?? ""
  };;${profileData.postalCode ?? ""};${profileData.country ?? ""}\n`;

  //Phone numbers
  if (profileData.businessPhones) {
    vcard += `TEL;TYPE=work:${profileData.businessPhones}\n`;
  }

  if (profileData.mobilePhone) {
    vcard += `TEL;TYPE=cell:${profileData.mobilePhone}\n`;
  }

  //Email
  vcard += `EMAIL;TYPE=work:${profileData.mail}\n`;

  vcard += "END:VCARD";

  return vcard;
};
