export const InputField = (props: {
  id?: string;
  label?: string;
  defaultValue?: string;
  type: React.HTMLInputTypeAttribute;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}) => {
  return (
    <div className={`flex flex-col ${props.className}`}>
      {props.label && (
        <label htmlFor={props.id} className="ml-3">
          {props.label}
        </label>
      )}

      <input
        id={props.id}
        className="mb-3 rounded-xl p-3 dark:bg-[#181414] dark:text-white"
        defaultValue={props.defaultValue}
        type={props.type}
        onChange={props.onChange}
      ></input>
    </div>
  );
};
